import { navigate } from 'gatsby'
import React, { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Box, Button, Container, Flex, Heading, Text } from '@chakra-ui/react'
import DuckPage from '../../components/duckPage'

import H3SVG from '../../icons/H3.svg'
import CheckSVG from '../../icons/check.svg'
import { useAuth } from '../../hooks/useAuth'
import { getGroupByRfid } from '../../services/group'

const ConfirmPage = () => {
	const { profile } = useAuth()

	const groupsQuery = useQuery(['rfid', profile?.rfid], getGroupByRfid, {
		enabled: !!profile?.rfid
	})

	return (
		<DuckPage
			theme="light"
			access={5}
			title={
				<>
					RFID
					<br />
					Connected
				</>
			}
			topText="Congratulations you can now start playing games."
			primaryBtn={
				<Button variant="secondary" onClick={() => navigate('/')}>
					Done
				</Button>
			}
		>
			<Container
				variant="note"
				style={{
					backgroundImage: 'url(/images/transparent-note.svg)',
					backgroundSize: '100% 100%'
				}}
			>
				<Flex
					alignItems={'center'}
					direction="column"
					justifyContent="center"
				>
					<Flex direction="row">
						<CheckSVG />
						<Text ml="1" variant="lg">
							{profile?.shortCode || '......'}
						</Text>
					</Flex>
					{groupsQuery?.data && (
						<>
							<Text mt="1">Connected to</Text>
							<Text fontWeight={900}>
								{groupsQuery?.data?.name}
							</Text>
						</>
					)}
				</Flex>

				{/* <Heading as="h4" color="black" fontWeight="400">
					<strong fontWeight="700">
						{profile?.shortCode || '......'}
					</strong>{' '}
					linked
				</Heading> */}
				{/* <Box position="absolute" top="0" bottom="0" right="0" left="0">
					<H3SVG width="100%" height="100%" />
				</Box> */}
			</Container>
			<Text variant="m" top="20px" textAlign="center">
				Tip: If you encounter a problem try reconnecting your RFID
				wristband or ask a member of staff for a replacement
			</Text>
		</DuckPage>
	)
}

export default ConfirmPage
