import * as React from 'react'
import Layout from '../../layout'
import ConfirmPage from '../../pagesComponents/rfid/confirm'

const Confirm = () => {
	return (
		<Layout theme="light" title="Wristband Linked">
			<ConfirmPage />
		</Layout>
	)
}

export default Confirm
